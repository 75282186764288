import React from "react";
import styles from "./ChoosingUs.module.css";
import experience from "../../imag/news1.jpg";
import communication from "../../imag/news2.jpg";
import comfort from "../../imag/new3.jpg";

const columnsData = [
  {
    imgSrc: experience,
    altText: "experience",
    link: "/",
    heading: "Compassionate Care",
    text: "Ensuring every pet receives love and top-notch treatment.",
  },
  {
    imgSrc: communication,
    altText: "communication",
    link: "/",
    heading: "Community Engagement",
    text: "Hosting events to promote pet welfare and responsible ownership.",
  },
  {
    imgSrc: comfort,
    altText: "comfort",
    link: "/",
    heading: "Lifelong Support",
    text: "Providing ongoing guidance for all pet-related needs.",
  },
  {
    imgSrc: comfort,
    altText: "comfort",
    link: "/",
    heading: "Lifelong Support",
    text: "Providing ongoing guidance for all pet-related needs.",
  },
  {
    imgSrc: communication,
    altText: "communication",
    link: "/",
    heading: "Community Engagement",
    text: "Hosting events to promote pet welfare and responsible ownership.",
  },
  {
    imgSrc:
      "https://marketplace.canva.com/EAFfzUq2Ny0/2/0/1131w/canva-white-and-black-modern-business-article-page-document-LCLjyOkxxOM.jpg",
    altText: "experience",
    link: "/",
    heading: "Compassionate Care",
    text: "Ensuring every pet receives love and top-notch treatment.",
  },
];

const ChoosingUs = () => {
  return (
    <div className="mb-2">
      <div className="container overflow-hidden text-center mb-2">
        <p className={styles.heading}>Travel and Food</p>

        <div
          className={`row ${styles.sub} align-items-center justify-content-center`}
        >
          {columnsData.map((col, index) => (
            <div className="col-lg-4 col-md-3 col-sm-5 pb-5" key={index}>
              <img src={col.imgSrc} className="img-fluid" alt={col.altText} />
              <a href={col.link}>
                <p className={styles.logo_head}>{col.heading}</p>
              </a>
              <p className={styles.logo_text}>{col.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChoosingUs;
