import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import podcastbg from "../../imag/logobgimage.png";
import logoimg from "../../imag/podcastr.png";
import {
  MdOutlineMailOutline,
  MdLockOutline,
  MdOutlinePersonOutline,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { registerData } from "../../redux/thunks/registerThunk";
import { resetRegisterData } from "../../redux/slice/registerSlice";

const Register = () => {
 
  const navigate = useNavigate();
const dispatch=useDispatch();
const[errors,setError]=useState({ 
  name: '',
  username: '',
  email: '',
  password: '',
  password_confirmation: '',

})
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
  
   
  });


  const handleChange = (e) => {
    const { name, value ,required} = e.target;
    setFormData((formData)=>({
      ...formData,
      [name]: value,
    }));

    required &&
    setError((prevError) => ({
      ...prevError,
      [name]: value ? "" : "Field is required",
    }));
  };



  const handleSubmit = async (e) => {
   
    e.preventDefault();

    // if (password !== password_confirmation) {
    //   toast.error("Passwords do not match");
    //   return;
    // }

    

    try{


      if (!formData.name || !formData.email || !formData.username || !formData.password || !formData.password_confirmation) {
        toast.error("Please verify indicated fields");
       setError((prevError) => ({
          ...prevError,
          ...(!formData.name && {name:"Name is required"}),
          ...(!formData.email && {email:"Email is required"}),
          ...(!formData.username && {username:"Username is required"}),
          ...(!formData.password && {password:"Password is required"}),
          ...(!formData.password_confirmation && {password_confirmation:"Confirm Password is required"}),

  
  
        
        }));
        return;
      }
 
   dispatch(registerData(formData));
    }
    catch(error){
  throw error;
    }
  };

const registeredData=useSelector((state)=>state.registerDataState);

useEffect(()=>{
  if(registeredData.data){
    navigate('/login');
    dispatch(resetRegisterData());
    toast.success("User Registered Successfully")
  }
},[registeredData.data,dispatch,navigate])
  return (
    <Layout>
      <div className="container">
        <div className="register row">
          <div className="col-md-5">
            <div className="position-relative">
              <img
                src={podcastbg}
                className="img-fluid"
                alt="Podcast Background"
              />
              <div className="position-absolute logoname">
                <h2 className="text-white mb-4">Welcome to Podcastr</h2>
                <img
                  src={logoimg}
                  className="img-fluid mb-4"
                  alt="Podcastr Logo"
                />
                <p className="text-white my-4">
                  Join the World of Articles <br /> Join now Bloger Back.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-5 p-4">
            <h2 className="pb-2">Sign Up to your Account</h2>

            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-3">
                <p>Enter Name</p>
                <div className="position-relative">
                  <span className="login-icon">
                    <MdOutlinePersonOutline />
                  </span>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    name="name"
                    className="input"
                    id="exampleInputName"
                    required
                  />
                  {errors.name && <p className="error text-danger">{errors.name}</p>}</div>
              </div>
              <div className="mb-3">
                <p>Enter Username</p>
                <div className="position-relative">
                  <span className="login-icon">
                    <MdOutlinePersonOutline />
                  </span>
                  <input
                    type="text"
                    value={formData.username}
                    onChange={handleChange}
                    name="username"
                    className="input"
                    id="exampleInputUsername"
                    required
                  />
                  {errors.username && (
                    <p className="error text-danger">{errors.username}</p>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <p>Enter your email</p>
                <div className="position-relative">
                  <span className="login-icon">
                    <MdOutlineMailOutline />
                  </span>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    name="email"
                    className="input"
                    id="exampleInputEmail1"
                    required
                  />
                  {errors.email && <p className="error text-danger">{errors.email}</p>}
                </div>
              </div>
              <div className="mb-3">
                <p>Enter password</p>
                <div className="position-relative">
                  <span className="login-icon">
                    <MdLockOutline />
                  </span>
                  <input
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    name="password"
                    className="input"
                    id="exampleInputPassword1"
                    required
                  />
                  {errors.password && (
                    <p className="error text-danger">{errors.password}</p>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <p>Confirm password</p>
                <div className="position-relative">
                  <span className="login-icon">
                    <MdLockOutline />
                  </span>
                  <input
                    type="password"
                    value={formData.password_confirmation}
                    onChange={handleChange}
                    name="password_confirmation"
                    className="input"
                    id="exampleInputpassword_confirmation1"
                    required
                  />
                     {errors.password_confirmation && (
                    <p className="error text-danger">{errors.password_confirmation}</p>
                  )}
                </div>
              </div>
              <div className="mb-3">Note:Password must contain at least one capital letter,one number and one special character.</div>
              <button type="submit" className="btn-more btn-purple px-5 py-2">
                Submit
              </button>
              <NavLink to="/login" className="btn-outline-more px-5 py-2 mx-1">
                Already signed up?
              </NavLink>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
