// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import vector from "../imag/Designer-amico.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper/modules";

const Header = () => {
  return (
    <div>
      <div class="pt-4">
        <div class=" position-relative ">
          <div class="container">
            <div class="row  herotext">
              <div class=" col-lg-7 px-5">
                <h6 class="display-5  mt-4 mb-2  ">
                  Welcome to Onlekh
                </h6>
                <p class="fs-6 fw-medium mb-4">
                  Onlekh is a platform that provides you a place to share your thoughts and ideas with the world and get paid for it. 
            
                </p>
                <div className="d-flex hero-btn">
                  <div>
                    <Link
                      to="/login"
                      id="gold"
                      class=" btn-purple text-white  py-2 px-4 me-3 animated slideInLeft"
                    >
                      Create account{" "}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/about"
                      id="gold"
                      class=" btn-outline-more   py-2 px-4 me-3 animated slideInLeft"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
              <div class=" col-lg-5 d-flex justify-content-center">
                <img src={vector} className=" vector" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
