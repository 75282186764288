import React from "react";
import styles from "./ChoosingUs.module.css";
import profile from "../../imag/testimonial1.png";
import { Link } from "react-router-dom";

const ChoosingUs = ({ posts }) => {
  return (
    <div className="mb-2">
      {posts && posts.length > 0 ? (
        posts.map((article, index) => (
          <div className={styles.card_article} key={index}>
            <div className="row article-parent  justify-content-start">
              <div className="col-md-7 d-flex flex-column p-4">
                <Link to={`/blog/${article.name}`}>
                  <div
                    className="d-flex 
                  "
                  >
                    <Link to={`/profile/${article.author.id}`}>
                      {" "}
                      <img
                        src={profile}
                        className={styles.profile}
                        alt="profile"
                      />
                    </Link>
                    <div className={styles.profile_text}>
                      <h6 className="text-dark">{article.author.name}</h6>
                      <p className="text-start">{article.author.role}</p>
                    </div>
                  </div>
                  <p className={styles.logo_head}>{article.title}</p>
                </Link>
                <div
                  className={styles.logo_text}
                  dangerouslySetInnerHTML={{ __html: article.content }}
                />
              </div>
              <div className="col-md-5 d-flex align-items-center blog-image-parent">
                <img
                  src={`https://api.onlekh.com/storage/${article?.thumbnail_url}`}
                  className="img-fluid blog-images"
                  alt={article.title}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No articles found.</p>
      )}
    </div>
  );
};

export default ChoosingUs;
