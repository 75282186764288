import { createSlice } from "@reduxjs/toolkit";
import { getUsersList, registerData } from "../thunks/registerThunk";



const initialState={
data:null,
userData:null,
error:null,
loading:false
}
const registerSlice=createSlice({
    name:"registerSlice",
    initialState:initialState,
    reducers:{
        resetRegisterData:(state)=>{
state.data=null;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(registerData.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        .addCase(registerData.fulfilled,(state,action)=>{
            state.data=action.payload;
            state.loading=false;
            state.error=null;
        })
        .addCase(registerData.rejected, (state, action) => {
            state.loading = false;
            state.error = "failed to fetch";
          });

          builder.addCase(getUsersList.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        .addCase(getUsersList.fulfilled,(state,action)=>{
            state.userData=action.payload;
            state.loading=false;
            state.error=null;
        })
        .addCase(getUsersList.rejected, (state, action) => {
            state.loading = false;
            state.error = "failed to fetch";
          });
    }
})
export const {resetRegisterData}=registerSlice.actions
export default registerSlice.reducer;
