import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { MdOutlinePets } from "react-icons/md";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import SearchInput from "../Form/SearchInput";
import styles from "./Header.css";
// import logo from public/images/logo.png;

import { FaLongArrowAltRight } from "react-icons/fa";
import { getToken, Logout, removeToken } from "../../utils/storage";

const Header = () => {
  const [show, setShow] = useState(false);
  const [activeNav, setActiveNav] = useState([true, false, false, false]);
  const [expand, setExpand] = useState(false);

  const closeNav = () => {
    setExpand(false);
  };

  const token=getToken();

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const handleActiveNav = (i) => {
    let temp = activeNav;
    temp = temp.map((x) => (x = false));
    temp[i] = true;
    setActiveNav([...temp]);
    sessionStorage.setItem("NavbarMain", JSON.stringify(temp));
  };
  const [auth, setAuth] = useAuth();

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Success");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid container">
          <Link to="/" className="navbar-brand">
            <div className="logoimg">
              <img src="/images/onlekh.PNG" className="img-fluid" alt="My Image" />
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <p className="p-2 px-4">
              <NavLink
                to="/userhome"
                className={`${styles.nav_text} nav-link ${
                  activeNav[0] ? styles.active : ""
                }`}
                onClick={() => {
                  handleActiveNav(0);
                  closeNav();
                }}
                aria-current="page"
              >
                Feed
              </NavLink>
            </p>
            <p className=" p-2 px-4">
              <NavLink
                to="/createblog"
                className={`${styles.nav_text} nav-link ${
                  activeNav[0] ? styles.active : ""
                }`}
                onClick={() => {
                  handleActiveNav(0);
                  closeNav();
                }}
                aria-current="page"
              >
                Write
              </NavLink>
            </p>{" "}
            <ul className="navbar-nav">
              <div className="d-flex right-nav">
                {token ? (
                  // <li className="dark-font nav-item dropdown loginbtn ">
                  //   <ul className="dropdown-menu loginbtn">
                  //     <li>
                  //       <Link
                  //         to={`/dashboard/${
                  //           auth?.user?.role === 1
                  //             ? "admin"
                  //             : auth?.user?.role === 2
                  //             ? "shelter"
                  //             : "user"
                  //         }`}
                  //         className="dropdown-item"
                  //       >
                  //         Dashboard
                  //       </Link>
                  //     </li>
                  //     <li>
                  //       <div
                  //         onClick={()=>Logout()}
                        
                  //         className="dropdown-item "
                  //       >
                  //         Logout
                  //       </div>
                  //     </li>
                  //   </ul>
                  // </li>
                  <div
                          onClick={()=>Logout()}
                        
                          className="dropdown-item "
                        >
                          Logout
                        </div>
                ) : (
                  <li className="nav-item btn-purple  loginbtn ">
                    <Link to="/login" className="text-white  nav-link px-4 ">
                      Get Started
                    </Link>
                  </li>
                )}
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
