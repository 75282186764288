import React from "react";
import styles from "./Rightbar.module.css";
import experience from "../../imag/news1.jpg";
import communication from "../../imag/news2.jpg";
import comfort from "../../imag/new3.jpg";
import profile from "../../imag/testimonial1.png";

const columnsData = [
  {
    imgSrc: experience,
    altText: "experience",
    link: "/",
    heading: "Compassionate Care",
    text: "Ensuring every pet receives love and top-notch treatment.",
  },
  {
    imgSrc: communication,
    altText: "communication",
    link: "/",
    heading: "Community Engagement",
    text: "Hosting events to promote pet welfare and responsible ownership.",
  },
  {
    imgSrc: comfort,
    altText: "comfort",
    link: "/",
    heading: "Lifelong Support",
    text: "Providing ongoing guidance for all pet-related needs.",
  },
  {
    imgSrc: comfort,
    altText: "comfort",
    link: "/",
    heading: "Lifelong Support",
    text: "Providing ongoing guidance for all pet-related needs.",
  },
  {
    imgSrc: communication,
    altText: "communication",
    link: "/",
    heading: "Community Engagement",
    text: "Hosting events to promote pet welfare and responsible ownership.",
  },
  {
    imgSrc: experience,
    altText: "experience",
    link: "/",
    heading: "Compassionate Care",
    text: "Ensuring every pet receives love and top-notch treatment.",
  },
];
const FollowData = [
  {
    link: "/",
    heading: "Compassionate Care",
    text: "Ensuring every pet receives love and top-notch treatment.",
  },
  {
    link: "/",
    heading: "Compassionate Care",
    text: "Ensuring every pet receives love and top-notch treatment.",
  },
  {
    link: "/",
    heading: "Compassionate Care",
    text: "Ensuring every pet receives love and top-notch treatment.",
  },
  {
    link: "/",
    heading: "Compassionate Care",
    text: "Ensuring every pet receives love and top-notch treatment.",
  },
];
const Recommend = [
  { link: "/", text: "Money" },
  { link: "/", text: "Business" },
  { link: "/", text: "Ai" },
  { link: "/", text: "Health" },
  { link: "/", text: "Productivity" },
  { link: "/", text: "Creativity" },
];
const ChoosingUs = ({category}) => {
  return (
    <div className="mb-2">
      <div className="follow-sec mb-5">
        {FollowData.map((col) => (
          <div className=" pb-2 p-3 ">
            <div className="d-flex">
              <img src={profile} className={styles.profile} alt={col.altText} />
              <a href={col.link}>
                <div className={styles.follow}>
                  <p className={styles.follow_head}>{col.heading}</p>

                  <p className={styles.follow_text}>{col.text}</p>
                  <a href="/" className={styles.follow_btn}>
                    Follow
                  </a>
                </div>
              </a>
            </div>{" "}
          </div>
        ))}{" "}
      </div>{" "}
      <h6>Recommended tags</h6>
      <div className="follow-sec">
        <div className=" pb-2 p-3 ">
          <div className="d-flex flex-wrap ">
            {" "}
            {Array.isArray(category?.categories) &&
                  category?.categories?.map((value, i) => (
              <a href={value.title} className=" btn-purple px-2 mx-2 my-2 rounded">
                {value.title}
              </a>
            ))}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoosingUs;
