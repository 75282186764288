import { createSlice } from "@reduxjs/toolkit";
import { loginData } from "../thunks/loginThunk";


const initialState={
    data:null,
    loading:false,
    error:null

}
const loginSlice=createSlice({
    name:"loginSlice",
    initialState:initialState,
    reducers:{

        resetLoginData:((state)=>{
            state.data=null;
        })
    },
    extraReducers:(builder)=>{
        builder.addCase(loginData.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        builder.addCase(loginData.fulfilled,(state,action)=>{
            state.data=action.payload;
            state.loading=false;
            state.error=null;
        })
        builder.addCase(loginData.rejected,(state)=>{
            state.loading=false;
            state.error="failed to fetch"
        })
    }
})

export  const {resetLoginData}=loginSlice.actions
export default loginSlice.reducer;