import React, { useState, useEffect } from "react";
import UserMenu from "../../components/Layout/UserMenu";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Layout from "./../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import axios from "axios";
import profile from "../../imag/testimonial1.png";
import bgimage from "../../imag/bgimage.png";
import Travel from "../../pages/Travel";

const Profile = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    name: "John Lama",
    jobTitle: "Software Engineer",
    followers: 200,
    following: 220,
    totalArticles: 10,
    location: "Lalitpur, Nepal",
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get("https://api.onlekh.com/api/profile", {
          // Add headers or any necessary configurations here
        });
        if (response.data.success) {
        
          setProfileData(response.data.profile); // Assuming response.data.profile matches your expected data structure
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
        toast.error("Failed to fetch profile data");
      }
    };

    fetchProfileData();
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  return (
    <Layout>
      <img src={bgimage} className="img-fluid bg-img" alt="background"></img>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="profile-card text-center">
              <div className="profile-name">
                <img src={profile} className="text-center" alt="profile"></img>
                <h4 className="text-center">{profileData.name}</h4>
                <p className="text-center">{profileData.jobTitle}</p>
              </div>

              <div className="details">
                <div className="d-flex justify-content-between my-2 py-1">
                  <p>Followers</p>
                  <p>{profileData.followers}</p>
                </div>
                <div className="d-flex justify-content-between my-2 py-1">
                  <p>Following</p>
                  <p>{profileData.following}</p>
                </div>
                <div className="d-flex justify-content-between box my-2 py-3">
                  <p>Total Articles</p>
                  <p>{profileData.totalArticles}</p>
                </div>
                <div className="d-flex justify-content-between my-2 py-2">
                  <p>Location</p>
                  <p>{profileData.location}</p>
                </div>
              </div>
              <div className="btn-purple loginbtn px-4 py-3">
                <Link to="/profileedit">Edit Profile</Link>
              </div>
            </div>
          </div>
          <div className="col-md-9 pt-4">
            <div className="container">
              <h4 className="title">Latest Articles</h4>
              <Travel></Travel>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
