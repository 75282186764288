import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../utils/storage";

export const getAds = createAsyncThunk("getAds", async () => {
  try {
    const token=getToken()
    const response = await axios.get(
      `${process.env.REACT_APP_API}/postcustomads/all`, {
        headers: {
         
          Authorization:`Bearer ${token}`

        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});
