import { toast } from "react-toastify";

export const setToken=(data)=>{
    typeof window !=="undefined" && localStorage.setItem('podcastrToken',data);
}
export const getToken=()=>{
  return  typeof window !=="undefined" && localStorage.getItem('podcastrToken');
}

export const removeToken=()=>{
    typeof window !=="undefined" && localStorage.removeItem('podcastrToken');
}
export const Logout=()=>{
    typeof window !=="undefined" && localStorage.removeItem('podcastrToken');
    typeof window !=="undefined" && localStorage.removeItem('podcastrUserDetails');


    toast.success("Logout Successfully");
    window.location.href="/login";
}

export const setUser=(userDetails)=>{
    typeof window !=="undefined" && localStorage.setItem('podcastrUserDetails', JSON.stringify(userDetails));
}
export const getUser=()=>{
  return JSON.parse(  typeof window !=="undefined" && localStorage.getItem('podcastrUserDetails'));
}

export const removeUser=()=>{
    typeof window !=="undefined" && localStorage.removeItem('podcastrUserDetails');
}