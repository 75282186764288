import React from 'react'
import { getToken } from '../utils/storage'
import { Navigate } from 'react-router-dom';

const ProtectiveRoute = ({children}) => {

    const token=getToken();
  
  return token? children :<Navigate to={"/login"}/> 
}

export default ProtectiveRoute
