import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";


export const loginData = createAsyncThunk("loginData", async (data) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/login`,
      data,
      // {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //   },
      // }
    );

    return result.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors!==null) {
      const errors = error.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          errors[key].forEach((message) => {
            toast.error(message);
            return;
          });
        }
      }
    } else {
      toast.error(error.response.data.message);
      return;
}
    throw error;
  }
});
