// pages/Admin/Users.js
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import AdminMenu from "../../components/Layout/AdminMenu";
import { Link } from "react-router-dom";
import AdminDashboard from "../../pages/Admin/AdminDashboard";
import Articlespage from "../../pages/Articlespage";
import useUsers from "../../hooks/useUsers"; // Corrected the import path

import { AuthProvider } from "../../hooks/useAuth";
import Index from "./Posts/Index";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../redux/thunks/categoryThunk";
import { getBlogs } from "../../redux/thunks/blogThunk";

const Users = () => {
  const { filteredUsers, setFilter } = useUsers(); // Destructure correctly
  const categoryData = useSelector((state) => state.categoryDataState.getData);
  const blogData = useSelector((state) => state.blogDataState.getData);
  console.log(blogData, "...");

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    dispatch(getCategory());
    dispatch(getBlogs(currentPage));
  }, [dispatch, currentPage]);

  const [selectedNav, setSelectedNav] = useState("For You");
  const [selectedPosts, setSelectedPosts] = useState([]);

  useEffect(() => {
    if (selectedNav === "For You") {
      setSelectedPosts(blogData?.posts?.data);
    } else {
      const selectedCategory = categoryData?.categories?.find(
        (cat) => cat.title === selectedNav
      );
      setSelectedPosts(selectedCategory?.posts || []);
    }
  }, [selectedNav, categoryData, blogData]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < blogData?.posts?.last_page) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <Layout>
      <AuthProvider>
        <AdminDashboard></AdminDashboard>
      </AuthProvider>
      <div className="container p-3 pt-4">
        <div className="row">
          <div className=" pt-4">
            <Index  posts={selectedPosts} />
            {selectedNav === "For You" &&
            <div className="pagination-controls">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="btn btn-primary"
              >
                Previous
              </button>
              <span className="px-3">Page {currentPage}</span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === blogData?.posts?.last_page}
                className="btn btn-primary"
              >
                Next
              </button>
            </div>
}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Users;
