import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { getToken } from "../../utils/storage";

export const postBlog = createAsyncThunk("postBlog", async (data) => {
  try {
    const token = getToken();
    const result = await axios.post(
      `${process.env.REACT_APP_API}/post/store`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return result.data;
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.errors !== null
    ) {
      const errors = error.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          errors[key].forEach((message) => {
            toast.error(message);
          });
        }
      }
    } else {
      toast.error(error.response.data.message);
    }
    throw error;
  }
});

export const getBlogs = createAsyncThunk("getBlogs", async (page) => {
  try {


    const response = await axios.get(`${process.env.REACT_APP_API}/blogs?page=${page}`, 

  );

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const getBlogBySlug = createAsyncThunk("getBlogBySlug", async (slug) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/blogs/${slug}`
    );
   
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const getBlogBySlugAdmin = createAsyncThunk(
  "getBlogBySlugAdmin",
  async (slug) => {
    try {
      const token = getToken();

      const response = await axios.get(
        `${process.env.REACT_APP_API}/post/${slug}/edit`,
        {
          headers: {
            // 'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      );
    
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const increaseBlugView = createAsyncThunk(
  "increaseBlugView",
  async (data) => {
    try {
      // const token=getToken()

      const result = await axios.post(
        `${process.env.REACT_APP_API}/post/views/increment`,
        data
        // {
        //   headers: {
        //     // "Access-Control-Allow-Origin": "*",
        //     // "Content-Type":"multipart/form-data",
        //     Authorization:`Bearer ${token}`

        //   },
        // }
      );

      return result.data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors !== null
      ) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            errors[key].forEach((message) => {
              toast.error(message);
            });
          }
        }
      } else {
        toast.error(error.response.data.message);
      }
      throw error;
    }
  }
);

export const updateBlog = createAsyncThunk("updateBlog", async (data) => {
  try {
    const token = getToken();
    const result = await axios.post(
      `${process.env.REACT_APP_API}/post/update`,
      data,
      {
        headers: {
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return result.data;
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.errors !== null
    ) {
      const errors = error.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          errors[key].forEach((message) => {
            toast.error(message);
          });
        }
      }
    } else {
      toast.error(error.response.data.message);
    }
    throw error;
  }
});

export const deleteBlog = createAsyncThunk("deleteBlog", async (slug) => {
  try {
    const token = getToken();

    const response = await axios.delete(
      `${process.env.REACT_APP_API}/post/delete?id=${slug}`,
      {
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    );
   
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const assignAdsToBlog = createAsyncThunk("assignAdsBlog", async ({postId,adsId,data}) => {
  try {
    const token=getToken()
  
    const result = await axios.post(
      `${process.env.REACT_APP_API}/post/${postId}/update/${adsId}`,
      data,
      {
        headers: {
         
          Authorization:`Bearer ${token}`

        },
      }
    );

    return result.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors!==null) {
      const errors = error.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          errors[key].forEach((message) => {
            toast.error(message);
            return;
          });
        }
      }
    } else {
      toast.error(error.response.data.message);
      return;
}
    throw error;
  }
});
