import React from "react";
import Layout from "../../../components/Layout/Layout";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import "./Style.css";

const Notification = () => {
  const randomData = [
    {
      imageUrl:
        "https://img.freepik.com/premium-vector/cartoon-drawing-man-with-glasses-beard_410516-87277.jpg?w=740", // Add appropriate image URL or path
      title: "Babita Kumari liked your post.",
      uploadedTime: "7 hours ago",
    },
    {
      imageUrl:
        "https://img.freepik.com/premium-vector/cartoon-drawing-man-with-glasses-beard_410516-87277.jpg?w=740", // Add appropriate image URL or path
      title: "Babita Kumari Commented on your post.",
      uploadedTime: "7 hours ago",
    },
    {
      imageUrl:
        "https://img.freepik.com/premium-vector/cartoon-drawing-man-with-glasses-beard_410516-87277.jpg?w=740", // Add appropriate image URL or path
      title: "Babita Kumari Reshared your post.",
      uploadedTime: "7 hours ago",
    },
    {
      imageUrl:
        "https://img.freepik.com/premium-vector/cartoon-drawing-man-with-glasses-beard_410516-87277.jpg?w=740", // Add appropriate image URL or path
      title: "Babita Kumari Commented on your post.",
      uploadedTime: "7 hours ago",
    },
    {
      imageUrl:
        "https://img.freepik.com/premium-vector/cartoon-drawing-man-with-glasses-beard_410516-87277.jpg?w=740", // Add appropriate image URL or path
      title: "Babita Kumari started Following You.",
      uploadedTime: "7 hours ago",
    },
  ];

  return (
    <Layout>
      <main className="parent-article">
        <section>
          <h3 style={{ fontWeight: "bold" }}>Notifications</h3>
          <p>
            A quick glance at all your updates and alerts, keeping you in the
            loop effortlessly.
          </p>
        </section>

        <section className="notification-parent">
          {randomData.map((data, i) => (
            <section key={i} className="notification-box">
              <section className="notification-data">
                <img src={data.imageUrl} alt="User" className="user-avatar" />
                <div>
                  <p className="notification-title">{data.title}</p>
                  <p className="notification-time">{data.uploadedTime}</p>
                </div>
              </section>
              <section className="dots-icon">
                <HiOutlineDotsHorizontal
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                />
              </section>
            </section>
          ))}
        </section>
      </main>
    </Layout>
  );
};

export default Notification;
