import { createSlice } from "@reduxjs/toolkit";
import { getCategory } from "../thunks/categoryThunk";


const initialState={
   
    getData:null,
    error:null,
    loading:false
}
const categorySlice=createSlice({
    name:"categorySlice",
    initialState:initialState,
    reducers:{
    resetCategoryData:(state)=>{

    state.getData=null;
   }
    },
    extraReducers:(builder)=>{
       
        builder.addCase(getCategory.pending,(state,action)=>{
            state.loading=true;
            state.error=null
        })
        builder.addCase(getCategory.fulfilled,(state,action)=>{
            state.getData=action.payload;
            state.loading=false;
            state.error=null
        })
        builder.addCase(getCategory.rejected,(state,action)=>{
            
            state.loading=false;
            state.error="failed to fetch data"
        })
    }
})

export const {resetCategoryData}=categorySlice.actions;

export default categorySlice.reducer;