import React from "react";
import styles from "./Real.module.css";
import improveImg from "../../imag/blogger.png";
import { Link } from "react-router-dom";

const Real = () => {
  return (
    <div className="real">
      <div className=" mt-5 overflow-hidden container  ">
        <div className="row g-0">
          {" "}
          <div className="col-md-6">
            <div className={styles.text}>
              <p className={styles.subHeading}>
                Want to place your ads on our website?
              </p>
              <p className={styles.content}>
                Submit this form and we will get back to you as soon as possible
                <span className={styles.green}></span>
              </p>{" "}
              <div className="text-start mt-4 ">
                {" "}
                <a className="btn-purple px-4 py-2 mt-3" href="">Form</a>
              </div>
            </div>
          </div>
          <div className={`col-md-6 ${styles.imgDiv}`}>
            <img src={improveImg} alt="" className={styles.img} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Real;
