import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { getToken } from "../../utils/storage";

export const registerData = createAsyncThunk("registerData", async (data) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/signup`, data
    //   , {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //   },
    // }
  );
    // console.log(result.data, "k chha");
    return result.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const errors = error.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          errors[key].forEach((message) => {
            toast.error(message);
            return;
          });
        }
      }
    } else {
      toast.error("An unexpected error occurred.");
      return;
}
    throw error;
  }
});


export const getUsersList = createAsyncThunk("getUsersList", async (page) => {
  try {
    const token=getToken()
    const result = await axios.get(`${process.env.REACT_APP_API}/users?page=${page}`
      , {
      headers: {
        // "Access-Control-Allow-Origin": "*",
        Authorization:`Bearer ${token}`

      },
    }
  );
    // console.log(result.data, "k chha");
    return result.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const errors = error.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          errors[key].forEach((message) => {
            toast.error(message);
          });
        }
      }
    } else {
      toast.error("An unexpected error occurred.");
}
    throw error;
  }
});