import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Checkbox, Radio } from "antd";
import { Age } from "../components/Age";
import { Breeds } from "../components/breeds";

import Hero from "./Hero.jsx";
import ChoosingUs from "./ChoosingUs/ChoosingUs.js";
import Travel from "./Travel";
import Real from "./Real";

import "./Style.css";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../redux/thunks/categoryThunk.js";

const HomePage = () => {
  // Fetch all categories
  const categoryData=useSelector((state)=>state.categoryDataState.getData);

  const dispatch = useDispatch();
useEffect(()=>{
  dispatch(getCategory());

},[dispatch])



  // console.log(categoryData.getData?.categories,"helooo")
  return (
    <Layout>
      <Hero></Hero>
      {/* <div className="bg-color"> */}
      <div className="text-center pt-5 container ">
        <ChoosingUs data={categoryData} />
        
        {/* <Travel></Travel> */}
        {/* Adding pt-5, pb-5, pl-5, pr-5 for padding on all sides */}
      </div>{" "}
      <Real></Real>
      {/* </div> */}
    </Layout>
  );
};

export default HomePage;
