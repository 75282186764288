import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/Layout/Layout";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../redux/thunks/categoryThunk";
import { postBlog } from "../redux/thunks/blogThunk";
import { formats, modules } from "../components/Quill";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getUser } from "../utils/storage";
import ProtectiveRoute from "../components/ProtectiveRoute";
import toast from "react-hot-toast";
import { resetBlogData } from "../redux/slice/blogSlice";
import { useNavigate } from "react-router-dom";
const Blogs = () => {
  // const [title, setTitle] = useState("");
  // const [image, setImage] = useState(null);
  // const [category, setCategory] = useState("");
  // const [editorContent, setEditorContent] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState("");
const navigate=useNavigate();

  const userData=getUser()
 
  const [state, setState] = useState({
    title: '',
    category_id: '',
 
   
   
  });


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };


  const[errors,setError]=useState({ 
    title: '',
    category_id: '',
    thumbnail: '',
    content: '',
  
  })
  const quillRef = useRef(null);


  const handleChange = (e) => {
    const { name, value ,required} = e.target;
    setState((state)=>({
      ...state,
      [name]: value,
    }));

    required &&
    setError((prevError) => ({
      ...prevError,
      [name]: value ? "" : "Field is required",
    }));
  };
const blogPost=useSelector((state)=>state.blogDataState)

useEffect(()=>{
  if(blogPost.data){
    toast.success("Blog Created Successfully")
    dispatch(resetBlogData());
    navigate("/userhome")
  }
})


  // useEffect(() => {
  //   const quill = new Quill(quillRef.current, {
  //     theme: "snow",
  //     modules: {
  //       toolbar: [
  //         [{ header: "1" }, { header: "2" }, { font: [] }],
  //         [{ size: [] }],
  //         ["bold", "italic", "underline", "strike"],
  //         [{ list: "ordered" }, { list: "bullet" }],
  //         ["link", "image", "video"],
  //         ["clean"],
  //         [{ align: [] }],
  //       ],
  //     },
  //   });

  //   quill.on("text-change", () => {
  //     setEditorContent(quill.root.innerHTML);
  //   });
  // }, []);

  // const handleImageChange = (e) => {
  //   setImage(e.target.files[0]);
  // };

  // const handlePublish = async () => {
  //   const formData = new FormData();
  //   formData.append("title", title);
  //   formData.append("image", image);
  //   formData.append("category", category);
  //   formData.append("content", editorContent);

  //   try {
  //     const response = await axios.post(
  //       "https://api.onlekh.com/api/v1/blogs",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     console.log("Blog posted successfully", response.data);
  //     // Optionally, navigate to another page or show a success message
  //   } catch (error) {
  //     console.error("Error posting blog", error);
  //     // Handle error, show error message, etc.
  //   }
  // };

const handlePublish=(e)=>{

  e.preventDefault();
  try{

    const formData = new FormData();
    formData.append("title", state.title);
    formData.append("category_id", state.category_id);
    formData.append("user_id", userData?.id);
    formData.append("status", "draft");

    formData.append("thumbnail", image);
    formData.append("content", content);

    if (formData) {
      dispatch(postBlog(formData));
    }
  }
  catch(error){
throw error;
  }
}

const dispatch=useDispatch();
useEffect(()=>{
  dispatch(getCategory())
},[dispatch])

const category=useSelector((state)=>state.categoryDataState.getData);

// console.log(category,"category")
  return (
    
    <ProtectiveRoute>
    <Layout>
      <form   onSubmit={handlePublish}  className="container mt-4">
        <h2>Create a Blog Post</h2>
        <div className="form-group my-4">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={state.title}
            onChange={handleChange}
          />
        </div>
        <div className="form-group my-4">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            className="form-control"
            id="image"
            name="thumbnail"
              accept="image/*"
            onChange={handleImageChange}
          />
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{
                    width: "100%",
                    height: "250px",
                    margin: "10px 0px",
                    objectFit: "contain",
                  }}
                />
              )}
        </div>
        <div className="form-group my-4">
          <label htmlFor="category">Category</label>
          <select
            className="form-control"
            id="category"
            value={state.category_id}
            name="category_id"
            onChange={handleChange}
          >
            {Array.isArray(category?.categories) && category?.categories.map((categoryValue,i)=>(

            
            <option  value={categoryValue.id}>{categoryValue.title}</option>
          ))}
          </select>
        </div>
        <div className="form-group my-4">
          <label htmlFor="content">Content</label>
          <ReactQuill
                style={{ paddingTop: "1rem" }}
                theme="snow"
                value={content}
                onChange={setContent}
                modules={modules}
                formats={formats}
              />
        </div>
        <button type="submit"
          className="btn-purple text-white py-2 px-4 me-3 mt-3"
        
        >
          Publish
        </button>
      </form>
    </Layout>
    </ProtectiveRoute>
  );
};

export default Blogs;
