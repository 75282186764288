import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCategory = createAsyncThunk('getCategory', async() => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/categories`, 
      //   {
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //   },
      // }
    );
     
      return response.data;
    } catch (error) {
      throw error;
    }
  });
  