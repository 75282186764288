import { Layout } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { AuthProvider } from '../../../context/auth'
import AdminDashboard from '../AdminDashboard'
import { useDispatch, useSelector } from 'react-redux'
import { getCategory } from '../../../redux/thunks/categoryThunk'
import { useNavigate, useParams } from 'react-router-dom'
import { getUser } from '../../../utils/storage'
import { resetBlogData, resetBlogSlugData, resetBlogSlugDataAdmin, resetBlogUpdateData } from '../../../redux/slice/blogSlice'
import toast from 'react-hot-toast'
import { formats, modules } from "../../../components/Quill";
import ReactQuill from "react-quill";
import { getBlogBySlug, getBlogBySlugAdmin, postBlog, updateBlog } from '../../../redux/thunks/blogThunk'
const Edit = () => {
    const quillRef = useRef(null);

    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState(null);
    const [content, setContent] = useState("");
  const navigate=useNavigate();
  const { slug } = useParams();
  const dispatch=useDispatch();

  const [state, setState] = useState({
    title: '',
    category_id: '',
 
   
   
  });

  useEffect(() => {
    if (slug) {
      dispatch(getBlogBySlugAdmin(slug));
    }
    return()=>{

        dispatch(resetBlogSlugDataAdmin());
    }
  }, [dispatch, slug]);

  

  
  const slugData = useSelector((state) => state.blogDataState);
  const blogData = slugData.getSlugDataAdmin;





  useEffect(() => {
    if (blogData?.post) {
      setContent(blogData?.post?.content);
      setState({title:blogData?.post?.title,category_id:blogData?.post?.category?.id});
      

    }
  }, [blogData?.post]);

    const userData=getUser()
    // console.log(user_id,"lll")
   


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
        if (file) {
          const reader = new FileReader();
          reader.onload = function (e) {
            setSelectedImage(e.target.result);
          };
          reader.readAsDataURL(file);
        } else {
          setSelectedImage(null);
        }
      };



      const[errors,setError]=useState({ 
        title: '',
        category_id: '',
        thumbnail: '',
        content: '',
      
      })
    
    
      const handleChange = (e) => {
        const { name, value ,required} = e.target;
        setState((state)=>({
          ...state,
          [name]: value,
        }));
    
        required &&
        setError((prevError) => ({
          ...prevError,
          [name]: value ? "" : "Field is required",
        }));
      };

   
    
    useEffect(()=>{
      if(slugData.updateData){
        toast.success("Blog Updated Successfully")
        dispatch(resetBlogUpdateData());

        navigate("/admin/allpost")
      }
    })


    const handlePublish=(e)=>{

        e.preventDefault();
        try{
      
          const formData = new FormData();
          formData.append("title", state.title);
          formData.append("category_id", state.category_id);
          formData.append("user_id", userData?.id);
          formData.append("id", blogData?.post?.id);
          formData.append("_method", "PUT");
          formData.append("tags", "");
          formData.append("content_ad", "");
          formData.append("name", "");


          formData.append("status", "draft");
      
         image &&  formData.append("thumbnail", image);
          formData.append("content", content);
      
          if (formData) {
            dispatch(updateBlog(formData));
          }
        }
        catch(error){
      throw error;
        }
      }


    useEffect(()=>{
      dispatch(getCategory())
    },[dispatch])
    
    const category=useSelector((state)=>state.categoryDataState.getData);

  return (
<Layout>
      <AuthProvider>
        <AdminDashboard></AdminDashboard>
      </AuthProvider>
      <div className="container p-3 pt-4">
        <div className="row">
          <div className=" pt-4">
          <form  onSubmit={handlePublish}   className="container mt-4">
        <h2>Edit a Blog Post</h2>
        <div className="form-group my-4">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value ={state.title}
            onChange={handleChange}
          />
        </div>
        <div className="form-group my-4">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            className="form-control"
            id="image"
            name="thumbnail"
              accept="image/*"
            onChange={handleImageChange}
          />
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{
                    width: "100%",
                    height: "250px",
                    margin: "10px 0px",
                    objectFit: "contain",
                  }}
                />
              ):(
                <img
                  src={`https://api.onlekh.com/storage/${blogData?.post?.thumbnail_url}`}
                  alt="Selected"
                  style={{
                    width: "300px",
                    height: "200px",
                    margin: "10px 0px",
                    objectFit: "contain",
                  }}
                />
              )
              }
        </div>
        <div className="form-group my-4">
          <label htmlFor="category">Category</label>
          <select
            className="form-control"
            id="category"
            value={state.category_id}
            name="category_id"
            onChange={handleChange}
          >
            {Array.isArray(category?.categories) && category?.categories.map((categoryValue,i)=>(

            
            <option  value={categoryValue.id}>{categoryValue.title}</option>
          ))}
          </select>
        </div>
        <div className="form-group my-4">
          <label htmlFor="content">Content</label>
          <ReactQuill
                style={{ paddingTop: "1rem" }}
                theme="snow"
                value={content}
                onChange={setContent}
                modules={modules}
                formats={formats}
              />
        </div>
        <button type="submit"
          className="btn-purple text-white py-2 px-4 me-3 mt-3"
        
        >
          Publish
        </button>
      </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Edit
