import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";
import AdminDashboard from "./AdminDashboard"; // Adjusted the path
import { AuthProvider } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList } from "../../redux/thunks/registerThunk";

const Users = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const usersList = useSelector((state) => state.registerDataState);

  useEffect(() => {
    dispatch(getUsersList(currentPage));
  }, [dispatch, currentPage]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < usersList?.userData?.users?.last_page) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <AuthProvider>
    <Layout>
      
        <AdminDashboard />
     
      <div className="container p-3 pt-4">
        <div className="row">
          <div className="col-md-9 pt-4">
            <h1>All Users</h1>
            <div className="w-75">
              <div className="row justify-content-center flex-wrap">
                {Array.isArray(usersList?.userData?.users?.data) &&
                  usersList?.userData?.users?.data?.map((value, i) => (
                    <div className="m-2 pt-3" key={value.id}>
                      <div className="card">
                        <div className="card-body py-4 px-3">
                          <img
                            src={`/api/v1/auth/user-photo/${value.id}`}
                            className="pet-image img-fluid"
                            alt={value.name}
                            style={{
                              height: "200px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <div className="card-body">
                            <h5 className="card-title">
                              <Link to={`/user-info/${value.id}`} className="link">
                                {value.name}
                              </Link>
                              <h6>{value.address}</h6>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="pagination-controls">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="btn btn-primary"
              >
                Previous
              </button>
              <span className="px-3">Page {currentPage}</span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === usersList?.userData?.users?.last_page}
                className="btn btn-primary"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    </AuthProvider>
  );
};

export default Users;
