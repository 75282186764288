import React, { useState, useEffect } from "react";
import Layout from "./../../components/Layout/Layout";
import profile from "../../imag/testimonial1.png";
import bgimage from "../../imag/bgimage.png";
import axios from "axios";
import toast from "react-hot-toast";

const Profile = () => {
  const [userData, setUserData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    phone: "",
    address: "",
  });

  useEffect(() => {
    // Fetch user data from backend when component mounts
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get("http://your-laravel-backend/api/user", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      });

      const { name, username, email, phone, address } = response.data;
      setUserData({
        ...userData,
        name,
        username,
        email,
        phone,
        address,
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    }
  };

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      username,
      email,
      password,
      newPassword,
      confirmPassword,
      phone,
      address,
    } = userData;

    // Validate password change inputs
    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match");
      return;
    }

    try {
      const response = await axios.patch(
        "https://api.onlekh.com/api/user/update",
        {
          name,
          username,
          email,
          password,
          newPassword,
          phone,
          address,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Profile updated successfully");
        // Optionally update local state or perform other actions upon successful update
      } else {
        toast.error(response.data.message || "Profile update failed");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  return (
    <Layout>
      <img src={bgimage} className="img-fluid bg-img" alt="background"></img>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="profile-card text-center">
              <div className="profile-name">
                <img src={profile} className="text-center" alt="profile"></img>
                <h4 className="text-center">{userData.name}</h4>
                <p className="text-center">{userData.username}</p>
              </div>

              <div className="details">
                <div className="d-flex justify-content-between my-2 py-1">
                  <p>Followers</p>
                  <p>200</p>
                </div>
                <div className="d-flex justify-content-between my-2 py-1">
                  <p>Following</p>
                  <p>220</p>
                </div>
                <div className="d-flex justify-content-between box my-2 py-3">
                  <p>Total Articles</p>
                  <p>10</p>
                </div>
                <div className="d-flex justify-content-between my-2 py-2">
                  <p>Location</p>
                  <p>{userData.address}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 pt-4">
            <div className="container">
              <form onSubmit={handleSubmit}>
                <h4 className="title">Basic Information</h4>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={userData.name}
                    onChange={handleChange}
                    placeholder="Enter Your Name"
                    autoFocus
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    value={userData.username}
                    onChange={handleChange}
                    placeholder="Enter User Name"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                    placeholder="Enter Your Email"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={userData.password}
                    onChange={handleChange}
                    placeholder="Enter Your Password"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    value={userData.phone}
                    onChange={handleChange}
                    placeholder="Enter Your Phone"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    value={userData.address}
                    onChange={handleChange}
                    placeholder="Enter Your Address"
                  />
                </div>
                <div className="box py-4 my-3">
                  <h4>Change Password</h4>
                  <div className="mb-3">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={userData.password}
                      onChange={handleChange}
                      placeholder="Previous Password"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      className="form-control"
                      name="newPassword"
                      value={userData.newPassword}
                      onChange={handleChange}
                      placeholder="New Password"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      value={userData.confirmPassword}
                      onChange={handleChange}
                      placeholder="Confirm New Password"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn-purple text-white px-4 py-1"
                >
                  UPDATE
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
