import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./style.css";

const UserProfilePage = () => {
  const [isEditing, setIsEditing] = useState(false);

  const user = {
    name: "John Lama",
    jobTitle: "Software Engineer",
    followers: 654,
    followings: 289,
    totalArticles: 55,
    location: "Karachi Pakistan",
    imageUrl:
      "https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500", // Replace with actual image URL
  };

  const articles = [
    {
      id: 1,
      title: "The resume got engineer a $300 job at Google.",
      description: "The modern programming of hiring skills.",
      imageUrl:
        "https://marketplace.canva.com/EAFfzUq2Ny0/2/0/1131w/canva-white-and-black-modern-business-article-page-document-LCLjyOkxxOM.jpg", // Replace with actual image URL
    },
    {
      id: 2,
      title: "The resume got engineer a $300 job at Google.",
      description: "The modern programming of hiring skills.",
      imageUrl:
        "https://marketplace.canva.com/EADankCeYjw/2/0/1236w/canva-menswear-fashion-magazine-article-page-yCQRkTaMwDg.jpg", // Replace with actual image URL
    },
    {
      id: 3,
      title: "The resume got engineer a $300 job at Google.",
      description: "The modern programming of hiring skills.",
      imageUrl:
        "https://marketplace.canva.com/EADankCeYjw/2/0/1236w/canva-menswear-fashion-magazine-article-page-yCQRkTaMwDg.jpg", // Replace with actual image URL
    },
    {
      id: 4,
      title: "The resume got engineer a $300 job at Google.",
      description: "The modern programming of hiring skills.",
      imageUrl:
        "https://marketplace.canva.com/EADankCeYjw/2/0/1236w/canva-menswear-fashion-magazine-article-page-yCQRkTaMwDg.jpg", // Replace with actual image URL
    },
  ];

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  return (
    <Layout>
      <div>
        <img
          src="https://plus.unsplash.com/premium_photo-1676508230431-8928ff5a8076?q=80&w=1915&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt=""
          style={{ height: "170px", width: "100%", objectFit: "cover" }}
        />
        <div className="profile-page">
          <div
            className="profile-sidebar"
            style={{
              background: "white",
              marginTop: "-80px",
              border: "1px solid gray",
              padding: "20px",
              color: "#8C8C8C",
              height: "fit-content",
            }}
          >
            <img
              src={user.imageUrl}
              alt={user.name}
              className="profile-img"
              style={{
                marginTop: "-70px",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
              }}
            />
            <h3 style={{ fontWeight: "bolder", color: "black" }}>
              {user.name}
            </h3>
            <p>{user.jobTitle}</p>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Followers:</p>
              <p>{user.followers}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Followings:</p>
              <p>{user.followings}</p>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Total Articles:</p>
              <p>{user.totalArticles}</p>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Location:</p>
              <p>{user.location}</p>
            </div>
            <button className="edit-profile-btn" onClick={toggleEdit}>
              {isEditing ? "View Profile" : "Edit Profile"}
            </button>
          </div>
          {isEditing ? (
            <div className="form-container">
              <h2>Basic Information</h2>
              <div className="form-section">
                <input type="text" placeholder="First Name" />
                <input type="text" placeholder="Last Name" />
              </div>
              <input
                type="text"
                placeholder="Headline"
                style={{ marginTop: "25px" }}
              />
              <input
                type="email"
                placeholder="Email Address"
                style={{ marginTop: "25px" }}
              />
              <input
                type="tel"
                placeholder="Mobile Number"
                style={{ marginTop: "25px" }}
              />
              <input
                type="text"
                placeholder="Address Line 1"
                style={{ marginTop: "25px" }}
              />
              <input
                type="text"
                placeholder="Address Line 2"
                style={{ marginTop: "25px" }}
              />
              <h2 style={{ marginTop: "40px" }}>Account Settings</h2>
              <h3>Change Password</h3>
              <input
                type="password"
                placeholder="Old Password"
                style={{ marginTop: "25px" }}
              />
              <input
                type="password"
                placeholder="New Password"
                style={{ marginTop: "25px" }}
              />
              <input
                type="password"
                placeholder="Confirm New Password"
                style={{ marginTop: "25px" }}
              />
              <button
                type="submit"
                className="save-button"
                style={{ marginTop: "25px" }}
              >
                Save
              </button>
            </div>
          ) : (
            <div className="profile-content">
              <h3 style={{ fontSize: "30px" }}>Latest Articles</h3>
              <hr
                style={{
                  color: "black",
                  borderBottom: "2px solid black",
                }}
              />
              <div className="articles">
                {articles.map((article) => (
                  <div key={article.id} className="article">
                    <div className="image-outside">
                      <img src={article.imageUrl} alt={article.title} />
                    </div>
                    <div style={{ padding: "10px" }}>
                      <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {article.title}
                      </h3>
                      <p>{article.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default UserProfilePage;
