import { createSlice } from "@reduxjs/toolkit";
import { getAds } from "../thunks/adsThunk";


const initialState={
   
    getData:null,
    error:null,
    loading:false
}
const adsSlice=createSlice({
    name:"adsSlice",
    initialState:initialState,
    reducers:{
    resetAdsData:(state)=>{

    state.getData=null;
   }
    },
    extraReducers:(builder)=>{
       
        builder.addCase(getAds.pending,(state,action)=>{
            state.loading=true;
            state.error=null
        })
        builder.addCase(getAds.fulfilled,(state,action)=>{
            state.getData=action.payload;
            state.loading=false;
            state.error=null
        })
        builder.addCase(getAds.rejected,(state,action)=>{
            
            state.loading=false;
            state.error="failed to fetch data"
        })
    }
})

export const {resetAdsData}=adsSlice.actions;

export default adsSlice.reducer;