import { configureStore } from "@reduxjs/toolkit";
import registerDataReducer from "./slice/registerSlice"
import loginDataReducer from "./slice/loginSlice"
import categoryDataReducer from "./slice/categorySlice"
import blogDataReducer from "./slice/blogSlice"
import adsDataReducer from "./slice/adsSlice"
const store =configureStore({
    reducer:{
        registerDataState:registerDataReducer,
        loginDataState:loginDataReducer,
        categoryDataState:categoryDataReducer,
        blogDataState:blogDataReducer,
        adsDataState:adsDataReducer
    }
});
export default store;