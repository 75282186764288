import {
  FaEnvelope,
  FaFacebook,
  FaLinkedin,
  FaMapMarker,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Footer = () => {
  const [formContent, setFormContent] = useState({});
  const form = useRef();

  const handleChange = (event) => {
    const { value, name } = event.target;

    setFormContent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div
      className="container-fluid  text-white-50 footer pt-5 mt-5 wow "
      id="dark"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-4  col-md-6">
            <h3 className="text-white mb-4">Contact us </h3>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3">
                <FaMapMarker />
              </i>
              Nepal
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3">
                <FaPhoneAlt />
              </i>
              +977-9843118673
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3">
                <FaEnvelope />
              </i>
              mail@onlekh.com
            </p>
          </div>
          {/* <div className="col-lg-4 col-md-6">
            <h3 className="text-white mb-4">Other Links</h3>
            <Link to="/about" className="btn  text-white-50">
              About Us
            </Link>
            <Link to="/contact" className="btn text-white-50">
              Contact Us
            </Link>
            <br></br>
            <Link to="/policy" className="btn text-white-50">
              Privacy Policy
            </Link>
            <Link to="/" className="btn text-white-50">
              Terms & Condition
            </Link>
          </div> */}

          {/* <div className="col-lg-4 col-md-6">
            <h3 className="text-white mb-4">Daily Updates?</h3>
            <p>Please message us for any enquiries</p>
            <div
              className="position-relative mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <form className="footer-form d-flex">
                <input
                  placeholder="Email"
                  name="from_email"
                  value={formContent.email}
                  onChange={handleChange}
                  className="form-control bg-transparent w-100 py-2 ps-4 pe-2 text-white my-2"
                  type="email"
                />

                <button
                  type="submit"
                  id="gold"
                  className="btn btn-light texty-2 m-2 me-2 text-dark"
                >
                  Send
                </button>
              </form>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; All Rights Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a href="/">Home</a>
                <a href="/">Cookies</a>
                <Link to="/contact" href="/">
                  Help
                </Link>
                <a href="/">FQAs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
