// src/hooks/useAuth.js
import { useContext, createContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    // Simulate fetching authentication data
    const fetchAuth = async () => {
      const authData = await getAuthData();
      setAuth(authData);
    };

    fetchAuth();
  }, []);

  return <AuthContext.Provider value={[auth]}>{children}</AuthContext.Provider>;
};

const getAuthData = async () => {
  // Simulate fetching authentication data
  return { user: "John Doe", isAuthenticated: true };
};
