import React from "react";
import styles from "./ChoosingUs.module.css";
import experience from "../../imag/news1.jpg";
import communication from "../../imag/news2.jpg";
import comfort from "../../imag/new3.jpg";
import { Link } from "react-router-dom";

const columnsData = [
  {
    imgSrc: experience,
    altText: "experience",
    link: "/",
    heading: "Compassionate Care",
    text: "Ensuring every pet receives love and top-notch treatment.",
  },
  {
    imgSrc: communication,
    altText: "communication",
    link: "/",
    heading: "Community Engagement",
    text: "Hosting events to promote pet welfare and responsible ownership.",
  },
  {
    imgSrc: comfort,
    altText: "comfort",
    link: "/",
    heading: "Lifelong Support",
    text: "Providing ongoing guidance for all pet-related needs.",
  },
  {
    imgSrc: comfort,
    altText: "comfort",
    link: "/",
    heading: "Lifelong Support",
    text: "Providing ongoing guidance for all pet-related needs.",
  },
  {
    imgSrc: communication,
    altText: "communication",
    link: "/",
    heading: "Community Engagement",
    text: "Hosting events to promote pet welfare and responsible ownership.",
  },
  {
    imgSrc: experience,
    altText: "experience",
    link: "/",
    heading: "Compassionate Care",
    text: "Ensuring every pet receives love and top-notch treatment.",
  },
];


const stripHtmlTags = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

const ChoosingUs = ({ data }) => {
  return (
    <div className="mb-2">
  {Array.isArray(data?.categories) &&
    data.categories.map((value, i) => (
      <div key={i} className="container overflow-hidden text-center mb-2">
        <p className={styles.heading}>{value.title}</p>
        <div className={`row ${styles.sub} align-items-center justify-content-center`}>
        {Array.isArray(value.posts) &&
        value.posts.map((post, postIndex) => {
          const contentText = stripHtmlTags(post?.content);
          const shortContent = contentText.length > 50
            ? `${contentText.slice(0, 50)}...`
            : contentText;

          return (
            <div key={postIndex} className="col-lg-4 col-md-3 col-sm-5 pb-5">
              <img
                src={`https://api.onlekh.com/storage/${post?.thumbnail_url}`}
                className="img-fluid"
                alt={post.title}
              />
              <Link to={`/blog/${post.name}`}>
                <p className={styles.logo_head}>{post?.title}</p>
              </Link>
              <div className={styles.logo_text}>
                {shortContent}
              </div>
            </div>
          );
        })}

        </div>
      </div>
    ))}
</div>

  );
};

export default ChoosingUs;
