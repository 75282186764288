// src/hooks/useUsers.js
import { useState, useEffect } from "react";
import axios from "axios";

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/api/v1/auth/users");
        setUsers(response.data.users);
        setFilteredUsers(response.data.users); // Initialize with all users
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const setFilter = (filter) => {
    if (!filter) {
      setFilteredUsers(users); // Reset to all users if filter is empty
    } else {
      setFilteredUsers(
        users.filter((user) =>
          user.name.toLowerCase().includes(filter.toLowerCase())
        )
      );
    }
  };

  return { filteredUsers, setFilter };
};

export default useUsers;
