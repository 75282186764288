// App.js
import { Routes, Route } from "react-router-dom";
import React from "react";
import HomePage from "./pages/HomePage";
import Blogs from "./pages/Blogs";
import Pageofarticle from "./pages/Pageofarticle";
import Policy from "./pages/Policy";
import Pagenotfound from "./pages/Pagenotfound";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/user/Dashboard";
import PrivateRoutes from "./components/Routes/Private";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminRoutes from "./components/Routes/AdminRoutes";
import Users from "./pages/Admin/Users"; // Corrected import
import Allpost from "./pages/Admin/Allpost"; // Corrected import

import EditUser from "./pages/Admin/editUser";
import Profile from "./pages/user/Profile";
import Profile1st from "./pages/user/Profile1st";
import Products from "./pages/Products";
import Search from "./pages/Search";
import ProductDetails from "./pages/ProductDetails";
import Userhome from "./pages/Userhomepage";
import AOS from "aos";
import "aos/dist/aos.css";
import { MockAuthProvider } from "./context/MockAuthProvider"; // Import the mock provider
import { MockUsersProvider } from "./context/MockUsersProvider";
import Edit from "./pages/Admin/Posts/Edit";

import AssignAds from "./pages/Admin/Posts/AssignAds";

import UserProfilePage from "./pages/user/UserProfilePage";
import Notification from "./pages/user/notification/Notification";


function App() {
  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/userhome" element={<Userhome />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profileedit" element={<Profile1st />} />
        <Route path="/product/:slug" element={<ProductDetails />} />
        <Route path="/search" element={<Search />} />
        <Route path="/blog/:slug" element={<Pageofarticle />} />
        <Route path="/profile/:slug" element={<UserProfilePage />} />
        <Route path="/userhome/notification" element={<Notification />} />
        {/* <Route path="/dashboard" element={<PrivateRoutes />}>
            <Route path="user" element={<Dashboard />} />
            <Route path="user/profile" element={<Profile />} />
            <Route path="user/shelter-list" element={<Shelters />} />
          </Route> */}
        {/* admin */}
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="admin/users" element={<Users />} />{" "}
        <Route path="admin/allpost" element={<Allpost />} />
        <Route path="admin/edit/:slug" element={<Edit />} />

        <Route path="admin/assign-ads/:slug" element={<AssignAds />} />


        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/createblog" element={<Blogs />} />
        <Route path="*" element={<Pagenotfound />} />
        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
}

export default App;
