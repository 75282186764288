import React from "react";
import { NavLink, Link } from "react-router-dom";
import Layout from "./../../components/Layout/Layout";
import AdminMenu from "../../components/Layout/AdminMenu";
import { IoIosPeople } from "react-icons/io";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { useAuth } from "../../hooks/useAuth"; // Ensure the import path is correct

const AdminDashboard = () => {
  // const [auth] = useAuth(); // Make sure useAuth returns an array

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-4 usersandpost p-3 m-2">
          <Link to="/admin/users">
            <div className="d-flex">
              <IoIosPeople />
              <div className="admincard">
                <h4>Total Users:</h4>
                <p>1.3k</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-4 usersandpost p-3 m-2">
          <Link to="/admin/allpost">
            <div className="d-flex">
              <HiOutlineClipboardDocumentList />
              <div className="admincard">
                <h4>Total Posts:</h4>
                <p>3.3k</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
