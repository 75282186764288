import { createSlice } from "@reduxjs/toolkit";
import { assignAdsToBlog, deleteBlog, getBlogBySlug, getBlogBySlugAdmin, getBlogs, increaseBlugView, postBlog, updateBlog } from "../thunks/blogThunk";



const initialState={
    data:null,
    getSlugData:null,
    getSlugDataAdmin:null,
    viewData:null,
    updateData:null,
    deleteData:null,
    assignAdsData:null,
    getData:[],
    loading:false,
    error:null

}
const blogSlice=createSlice({
    name:"blogSlice",
    initialState:initialState,
    reducers:{

        resetBlogData:((state)=>{
            state.data=null;
        }),
        resetBlogSlugData:((state)=>{
            state.getSlugData=null;
        }),
        resetBlogSlugDataAdmin:((state)=>{
            state.getSlugDataAdmin=null;
        }),
        resetBlogUpdateData:((state)=>{
            state.updateData=null;
        }),
        resetBlogDeleteData:((state)=>{
            state.deleteData=null;
        }),
        resetAssignAdsData:((state)=>{
            state.assignAdsData=null;
        })
    },
    extraReducers:(builder)=>{
        builder.addCase(postBlog.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        builder.addCase(postBlog.fulfilled,(state,action)=>{
            state.data=action.payload;
            state.loading=false;
            state.error=null;
        })
        builder.addCase(postBlog.rejected,(state)=>{
            state.loading=false;
            state.error="failed to fetch"
        })
        builder.addCase(getBlogs.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        builder.addCase(getBlogs.fulfilled,(state,action)=>{
            state.getData=action.payload;
            state.loading=false;
            state.error=null;
        })
        builder.addCase(getBlogs.rejected,(state)=>{
            state.loading=false;
            state.error="failed to fetch"
        })

        builder.addCase(getBlogBySlug.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        builder.addCase(getBlogBySlug.fulfilled,(state,action)=>{
            state.getSlugData=action.payload;
            state.loading=false;
            state.error=null;
        })
        builder.addCase(getBlogBySlug.rejected,(state)=>{
            state.loading=false;
            state.error="failed to fetch"
        })
        builder.addCase(getBlogBySlugAdmin.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        builder.addCase(getBlogBySlugAdmin.fulfilled,(state,action)=>{
            state.getSlugDataAdmin=action.payload;
            state.loading=false;
            state.error=null;
        })
        builder.addCase(getBlogBySlugAdmin.rejected,(state)=>{
            state.loading=false;
            state.error="failed to fetch"
        })

        builder.addCase(increaseBlugView.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        builder.addCase(increaseBlugView.fulfilled,(state,action)=>{
            state.viewData=action.payload;
            state.loading=false;
            state.error=null;
        })
        builder.addCase(increaseBlugView.rejected,(state)=>{
            state.loading=false;
            state.error="failed to fetch"
        })


        builder.addCase(updateBlog.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        builder.addCase(updateBlog.fulfilled,(state,action)=>{
            state.updateData=action.payload;
            state.loading=false;
            state.error=null;
        })
        builder.addCase(updateBlog.rejected,(state)=>{
            state.loading=false;
            state.error="failed to fetch"
        })

        builder.addCase(assignAdsToBlog.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        builder.addCase(assignAdsToBlog.fulfilled,(state,action)=>{
            state.assignAdsData=action.payload;
            state.loading=false;
            state.error=null;
        })
        builder.addCase(assignAdsToBlog.rejected,(state)=>{
            state.loading=false;
            state.error="failed to fetch"
        })

        builder.addCase(deleteBlog.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        builder.addCase(deleteBlog.fulfilled,(state,action)=>{
            state.deleteData=action.payload;
            state.loading=false;
            state.error=null;
        })
        builder.addCase(deleteBlog.rejected,(state)=>{
            state.loading=false;
            state.error="failed to fetch"
        })
    }
})

export  const {resetBlogData,resetBlogSlugData,resetBlogUpdateData,resetBlogDeleteData, resetBlogSlugDataAdmin,resetAssignAdsData}=blogSlice.actions
export default blogSlice.reducer;