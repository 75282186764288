import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Checkbox, Radio } from "antd";
import { Age } from "../components/Age";
import { Breeds } from "../components/breeds";
import profile from "../imag/testimonial1.png";
import ChoosingUs from "./ChoosingUs/ChoosingUs.js";
import Travel from "./Travel";
import Real from "./Real";
import Articlenav from "../components/Layout/Articlesnav";
import Articlepage from "./Articlespage";
import Rightbar from "./Rightbar";

import "./Style.css";
import ProtectiveRoute from "../components/ProtectiveRoute.js";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../redux/thunks/categoryThunk.js";
import { getBlogs } from "../redux/thunks/blogThunk.js";

const HomePage = () => {
  const categoryData = useSelector((state) => state.categoryDataState.getData);
  const blogData = useSelector((state) => state.blogDataState.getData);
 
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategory());
    dispatch(getBlogs(currentPage));
  }, [dispatch, currentPage]);

  const [selectedNav, setSelectedNav] = useState("For You");
  const [selectedPosts, setSelectedPosts] = useState([]);

  useEffect(() => {
    if (selectedNav === "For You") {
      setSelectedPosts(blogData?.posts?.data);
    } else {
      const selectedCategory = categoryData?.categories?.find(
        (cat) => cat.title === selectedNav
      );
      setSelectedPosts(selectedCategory?.posts || []);
    }
  }, [selectedNav, categoryData, blogData]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < blogData?.posts?.last_page) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <ProtectiveRoute>
      <Layout>
        <div className="container mt-5">
          <h5 className="mb-2">Stay Informed and Inspired</h5>
          <p>
            Explore, engage, and discover a world of knowledge and inspiration
            in our feed.
          </p>
          <Link to="/createblog" className="sidebar d-flex mt-4">
            <img src={profile} alt="" className=""></img>
            <p className="mx-3">What you want to talk about today?</p>
          </Link>
        </div>
        {/* <div className="bg-color"> */}
        <div className=" pt-5 container ">
          <h3>Latest Articles</h3>

          {/* <Articlenav></Articlenav> */}
          <nav className="navbar navbar-expand-lg article-nav">
            <div className="container-fluid container">
              <div
                className="collapse cursor-pointer  navbar-collapse d-flex justify-content-between "
                id="navbarNav"
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedNav("For You");
                  }}
                  className={`${
                    selectedNav === "For You" ? "text-primary" : ""
                  } pointer`}
                >
                  For You
                </div>

                {Array.isArray(categoryData?.categories) &&
                  categoryData?.categories?.slice(0, 7)?.map((value, i) => (
                    <div
                      onClick={() => {
                        setSelectedNav(value.title);
                      }}
                      className={
                        selectedNav === value.title ? "text-primary" : ""
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {value.title}
                    </div>
                  ))}
              </div>
            </div>
          </nav>
          <div className="feed mt-5">
            <div className="row">
              <div className="col-lg-9 ">
                <Articlepage posts={selectedPosts} />
                {selectedNav === "For You" && (
                  <div className="pagination-controls">
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className="btn btn-primary"
                    >
                      Previous
                    </button>
                    <span className="px-3">Page {currentPage}</span>
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === blogData?.posts?.last_page}
                      className="btn btn-primary"
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
              <div className="col-lg-3  ">
                <Rightbar category={categoryData} />
              </div>
            </div>
          </div>
          {/* Adding pt-5, pb-5, pl-5, pr-5 for padding on all sides */}
        </div>{" "}
        {/* </div> */}
      </Layout>
    </ProtectiveRoute>
  );
};

export default HomePage;
