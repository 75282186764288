import React, { useEffect, useState } from "react";
import Layout from "./../../components/Layout/Layout";
import axios from "axios";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";
import podcastbg from "../../imag/logobgimage.png";
import logoimg from "../../imag/podcastr.png";
import { MdOutlineMailOutline, MdLockOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { loginData } from "../../redux/thunks/loginThunk";
import { setToken, setUser } from "../../utils/storage";
import { resetLoginData } from "../../redux/slice/loginSlice";

const Login = () => {

 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const[error,setError]=useState({
    username: "",
    password: "",
})
  const location = useLocation();


  const handleOnChange = (e) => {
    const { name, value ,required} = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    required &&
    setError((prevError) => ({
      ...prevError,
      [name]: value ? "" : "field is required",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state.email || !state.password ) {
      toast.error("Please verify indicated fields");
     setError((prevError) => ({
        ...prevError,
        ...(!state.email && {email:"Email is required"}),
        ...(!state.password && {password:"Pasword is required"}),
     

      
      }));
      return;
    }

  try {
    
    dispatch(loginData(state));
  } catch (err) {
    throw err;
  }
  };

  const loginFormData=useSelector((state)=>state.loginDataState);

useEffect(()=>{

  if(loginFormData.data){
    toast.success("Logged in successfully");
    setToken(loginFormData.data.token);
    setUser(loginFormData.data.user)
    navigate("/userhome")
    dispatch(resetLoginData());

  }
},[loginFormData.data,navigate,dispatch])



  return (
    <Layout>
      <div className="container">
        <div className="register row">
          <div className="col-md-5">
            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-3">
                <p>Enter your email</p>
                <div className="position-relative">
                  <span className="login-icon">
                    <MdOutlineMailOutline />
                  </span>
                  <input
                    type="email"
                    value={state.email}
                    name="email"
                    onChange={handleOnChange}
                    className="input"
                    id="exampleInputEmail1"
                    required
                  />
                   {error.email && <p className="error text-danger">{error.email}</p>}
                </div>
              </div>
              <div className="mb-3">
                <p>Enter your password</p>
                <div className="position-relative">
                  <span className="login-icon">
                    <MdLockOutline />
                  </span>
                  <input
                    type="password"
                    value={state.password}
                    name="password"
                    onChange={handleOnChange}
                    className="input"
                    id="exampleInputPassword1"
                    required
                  />
                   {error.password && <p className="error text-danger">{error.password}</p>}
                </div>
              </div>
              <div className="mb-3">
                <NavLink to="/forgot-password" className="btn">
                  Forgot Password?
                </NavLink>
              </div>
              <div className="d-flex">
                <button
                  type="submit"
                  className="btn-more btn-purple px-5 py-2 btn-login mx-1"
                >
                  Login
                </button>
                <NavLink
                  to="/register"
                  className="btn-outline-more px-5 py-2 mx-1"
                >
                  Sign Up
                </NavLink>
              </div>
            </form>
          </div>
          <div className="col-md-5 right-img">
            <div className="position-relative">
              <img
                src={podcastbg}
                className="img-fluid"
                alt="Podcast Background"
              />
              <div className="position-absolute logoname">
                <h2 className="text-white mb-4">Welcome to Onlekh</h2>
                <img
                  src={logoimg}
                  className="img-fluid mb-4"
                  alt="Onlekh Logo"
                />
                <p className="text-white my-4">
                  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
