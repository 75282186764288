import React, { useEffect, useState } from "react";
import styles from "./Pageofarticle.module.css";
// import experience from "../../images/news1.jpg";
// import communication from "../../images/news2.jpg";
// import comfort from "../../images/news3.jpg";
// import profile from "../../images/testimonial1.png";
import Rightbar from "../Rightbar";
import Layout from "../../components/Layout/Layout";
import { IoChatbubbleOutline, IoChatbubble } from "react-icons/io5";
import { IoIosHeartEmpty, IoIosHeart } from "react-icons/io";
import { RiShareForwardLine, RiShareForwardFill } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlogBySlug, increaseBlugView } from "../../redux/thunks/blogThunk";
const SinglePage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  
  useEffect(() => {
    if (slug) {
      dispatch(getBlogBySlug(slug));
    }
  }, [dispatch, slug]);

  const slugData = useSelector((state) => state.blogDataState);
  const blogData = slugData.getSlugData;


  useEffect(() => {
    if (blogData) {
      const dataToSend = {
        post_id: blogData?.post?.id,
      };
      dispatch(increaseBlugView(dataToSend));
    }
  }, [blogData, dispatch]);

  const [liked, setLiked] = useState(false);
  const [commented, setCommented] = useState(false);
  const [shared, setShared] = useState(false);
  const [comments, setComments] = useState([]);

  const handleLike = () => setLiked(!liked);
  const handleComment = () => setCommented(!commented);
  const handleShare = () => setShared(!shared);

  const handleAddComment = (newComment) => {
    setComments((prevComments) => [...prevComments, newComment]);
  };

  return (
    <Layout>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-8 offset-md-2">
            {blogData?.post?.custom_ad?.ad_code ? (
              <img
                src={`https://api.onlekh.com/storage/${blogData.post.custom_ad.ad_code}`}
                className="img-fluid p-2"
                alt={blogData?.title || "Image"}
              />
            ) : (
              <img
                src={`https://api.onlekh.com/storage/${blogData?.post?.category?.custom_ad?.ad_code}`}
                className="img-fluid p-2"
                alt={blogData?.title || "Image"}
              />
            )}
          </div>
        </div>
        <div className="mb-2">
          <div className={styles.card_article}>
            <div className="row p-4 justify-content-between">
              <h2 className={styles.logo_head}>{blogData?.post?.title}</h2>
              <p className={styles.logo_text}>{blogData?.content}</p>
              <Link to="#">
                <div className="d-flex my-4">
                  <img
                    src="https://randomuser.me/api/portraits/lego/1.jpg"
                    className={styles.profile}
                    alt="profile"
                  />
                  <div className={styles.profile_text}>
                    <h6 className="text-dark">
                      {blogData?.post?.author?.name}{" "}
                    </h6>
                    <p className="text-start">
                      {blogData?.post?.author?.username}{" "}
                    </p>
                  </div>
                </div>
              </Link>
              <div className={styles.sharing}>
                <div className="d-flex">
                  <p onClick={handleLike} style={{ cursor: "pointer" }}>
                    {liked ? <IoIosHeart /> : <IoIosHeartEmpty />} 23
                  </p>
                  <p onClick={handleComment} style={{ cursor: "pointer" }}>
                    <a href="#comment">
                      {commented ? <IoChatbubble /> : <IoChatbubbleOutline />} 2
                    </a>
                  </p>
                  <p onClick={handleShare} style={{ cursor: "pointer" }}>
                    {shared ? <RiShareForwardFill /> : <RiShareForwardLine />} 5
                  </p>
                </div>
              </div>
            </div>
            <img
              src={`https://api.onlekh.com/storage/${blogData?.post?.thumbnail_url}`}
              className="img-fluid p-4"
              alt={blogData?.title}
            />
            <div className="description">
              {/* <p>{blogData?.post?.content}</p> */}
              <div
                className={styles.logo_text}
                dangerouslySetInnerHTML={{ __html: blogData?.post?.content }}
              />
            </div>
            <CommentSection
              comments={comments}
              handleAddComment={handleAddComment}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const CommentSection = ({ comments, handleAddComment }) => {
  const [commentText, setCommentText] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (commentText.trim()) {
      handleAddComment(commentText);
      setCommentText("");
    }
  };

  return (
    <div className={styles.comment_section} id="comment">
      <h4>Comments</h4>
      <ul>
        {comments.map((comment, idx) => (
          <li key={idx}>{comment}</li>
        ))}
      </ul>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className="w-75"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          placeholder="Add a comment"
        />
        <br />
        <button className="btn btn-purple text-white" type="submit">
          Post
        </button>
      </form>
    </div>
  );
};

export default SinglePage;
