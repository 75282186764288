import { Layout } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AuthProvider } from "../../../context/auth";
import AdminDashboard from "../AdminDashboard";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../redux/thunks/categoryThunk";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../../../utils/storage";
import {
  resetAssignAdsData,
  resetBlogData,
  resetBlogSlugData,
  resetBlogSlugDataAdmin,
  resetBlogUpdateData,
} from "../../../redux/slice/blogSlice";
import toast from "react-hot-toast";
import { formats, modules } from "../../../components/Quill";
import ReactQuill from "react-quill";
import {
  assignAdsToBlog,
  getBlogBySlug,
  getBlogBySlugAdmin,
  postBlog,
  updateBlog,
} from "../../../redux/thunks/blogThunk";
import { getAds } from "../../../redux/thunks/adsThunk";
const AssignAds = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const dispatch = useDispatch();

  const [state, setState] = useState({adsId:""});

  useEffect(() => {
    if (slug) {
      dispatch(getBlogBySlugAdmin(slug));
    }
    return () => {
      dispatch(resetBlogSlugDataAdmin());
    };
  }, [dispatch, slug]);

  const slugData = useSelector((state) => state.blogDataState);
  const blogData = slugData.getSlugDataAdmin;

  useEffect(() => {
    if (blogData?.post) {
      setState({adsId:blogData?.post?.content_ad});
    }
  }, [blogData?.post]);
 

  const userData = getUser();


  const [error, setError] = useState({
   adsId:""
  });

  const handleChange = (e) => {
    const { name, value, required } = e.target;
    setState((state) => ({
      ...state,
      [name]: value,
    }));

    required &&
      setError((prevError) => ({
        ...prevError,
        [name]: value ? "" : "Field is required",
      }));
  };

  useEffect(() => {
    if (slugData.assignAdsData) {
      toast.success("Ads Assigned Successfully");
      dispatch(resetAssignAdsData());

      navigate("/admin/allpost");
    }
  });

  const handlePublish = (e) => {
    e.preventDefault();
    try {
      if(!state.adsId){
        toast.error("Please Verify Indicated Fields")
        setError({adsId:"Select any ads"})
        return;
      }
      const formData = new FormData();

      formData.append("_method", "PATCH");
    

      if (formData) {
        dispatch(assignAdsToBlog({data:formData, postId:slug, adsId:state.adsId}));
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    dispatch(getAds());
  }, [dispatch]);

  const ads = useSelector((state) => state.adsDataState.getData);

  return (
    <Layout>
      <AuthProvider>
        <AdminDashboard></AdminDashboard>
      </AuthProvider>
      <div className="container p-3 pt-4">
        <div className="row">
          <div className=" pt-4">
            <form onSubmit={handlePublish} className="container mt-4" noValidate>
              <h2>Assign an Ads To Blog Post</h2>
             
              <div className="form-group my-4">
                <label htmlFor="category">Select any ads</label>
                <select
                  className="form-control"
                  id="category"
                  value={state.adsId}
                  name="adsId"
                  onChange={handleChange}
                  required
                >
                  <option value="">Select any Ads</option>
                  {Array.isArray(ads?.custom_ads?.data) &&
                    ads?.custom_ads?.data?.map((item, i) => (
                      <option value={item.id}>
                        {item.title}
                      </option>
                    ))}
                </select>
                {error.adsId && <p>{error.adsId}</p>}
              </div>
            
              <button
                type="submit"
                className="btn-purple text-white py-2 px-4 me-3 mt-3"
              >
                Assign
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AssignAds;
