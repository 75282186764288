import React, { useState, useEffect } from "react";
import styles from "../../ChoosingUs/ChoosingUs.module.css";
import axios from "axios";
import profile from "../../../imag/testimonial1.png";
import { Link } from "react-router-dom";
import { deleteBlog, getBlogs } from "../../../redux/thunks/blogThunk";
import { useDispatch, useSelector } from "react-redux";
import { resetBlogDeleteData } from "../../../redux/slice/blogSlice";
import toast from "react-hot-toast";

const Index = ({ posts }) => {
  const dispatch = useDispatch();
  const deleteBlogDatas = useSelector((state) => state.blogDataState);
  useEffect(() => {
    if (deleteBlogDatas.deleteData) {
      toast.success("Blog Deleted Successfully");
      dispatch(resetBlogDeleteData());
      dispatch(getBlogs());
    }
  });
  return (
    <div className="mb-2">
      {posts && posts.length > 0 ? (
        posts.map((article, index) => (
          <div className={styles.card_article} key={index}>
            <div className="row p-4 justify-content-between w-100">
              <div className="col-md-5">
                <Link to={`/blog/${article.name}`}>
                  <div className="d-flex">
                    <img
                      src={profile}
                      className={styles.profile}
                      alt="profile"
                    />
                    <div className={styles.profile_text}>
                      <h6 className="text-dark">{article.author.name}</h6>
                      <p className="text-start">{article.author.role}</p>
                    </div>
                  </div>
                  <p className={styles.logo_head}>{article.title}</p>
                </Link>
                <div
                  className={styles.logo_text}
                  dangerouslySetInnerHTML={{ __html: article.content }}
                />
                <div className="d-flex gap-2">
                  <Link
                    to={`/admin/edit/${article.id}`}
                    className="bg-primary text-white px-2"
                  >
                    Edit
                  </Link>
                  <Link
                    to={`/admin/assign-ads/${article.id}`}
                    className="bg-primary text-white px-2"
                  >
                    Assign Ads
                  </Link>
                  <span
                    onClick={() => dispatch(deleteBlog(article.id))}
                    className="bg-danger text-white px-2 mx-2"
                  >
                    Delete
                  </span>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src={`https://api.onlekh.com/storage/${article?.thumbnail_url}`}
                  className="img-fluid"
                  alt={article.title}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No articles found.</p>
      )}
    </div>
  );
};

export default Index;
